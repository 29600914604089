

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #3366FF;
    gap: 80px;
}


.header {
    display: flex;
    flex-direction: column;    
}

h1 {
    color: white;
}

.logo {
    height: 150px;
}

.badges {
    display: flex;
    flex-direction: column;

    gap: 16px;
}

.badges img {
    width: 200px;
}