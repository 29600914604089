.stats{
    width: 100%;
    min-height: 100vh;

    padding: 64px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 16px;

    background-color: #3366FF;
}

.boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;

    
}

.boxes > div {
    background-color: white;
    border-radius: 16px;
    
    padding: 24px;
    width: 350px;
    max-width: 90vw;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boxes > div > h3 {
    margin: 0;
    font-size: 24px;
}
.boxes > div > span {
    margin: 0;
    font-size: 16px;
    margin-bottom: 8px;
}